import { Grid as AscendGrid, Link as AscendLink } from '@achieve/ascend'
import { MenuList, MenuItem } from '@mui/material'
import { useState, useEffect, useRef } from 'react'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import Section from 'components/Section'
import styles from './StickySubNavWrapper.module.scss'
import { useRouter } from 'next/router'
import { StickySubNavSection, StickySubNavSectionScroll } from './StickySubNavSection'
import { normalizeContent } from 'utils/shared/hacks'

function StickySubNavWrapper({ content, scrollRender = false }) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const [sectionContents, setSectionContents] = useState(content?.fields?.sectionContents)

  // Declare array in state for tracking position on page to highlight subnav bar
  const [intersectingElements, setIntersectingElements] = useState(
    new Array(sectionContents.length).fill(true)
  )

  // Create a ref for the subnav bar for better tabbing behavior
  const refContainer = useRef()

  // Create a ref for each major section within the policies main text area.
  const refElements = useRef()
  refElements.current = []

  // IntersectionObserver to track sections are intersecting viewport by more than {threshold}%
  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersectionEvent, {
      rootMargin: '-150px', //viewport margin to be excluded when calculating section intersection
      threshold: 0.01, //1% of section visible in viewport is threshold to be intersecting
    })

    refElements.current.forEach((ref) => {
      observer.observe(ref)
    })
    return () => {
      refElements.current.forEach((ref) => {
        if (ref) observer.unobserve(ref)
      })
    }
  }, [sectionContents])

  function handleIntersectionEvent(entries) {
    const sectionObservedIndex = entries[0]?.target.getAttribute('data-section-index')
    const sectionIsIntersecting = entries[0].isIntersecting

    setIntersectingElements((prev) => {
      if (sectionObservedIndex == 0 || prev[sectionObservedIndex - 1] === false) {
        prev[sectionObservedIndex] = sectionIsIntersecting
      }
      return [...prev]
    })
  }

  // Update state on link click
  const updateIntersectingElementsOnClick = (e) => {
    let sectionClicked = 0
    refContainer.current.focus()

    if (e?.target?.getAttribute('section-index')) {
      sectionClicked = e?.target?.getAttribute('section-index')
    }

    setIntersectingElements((prev) => {
      const newState = new Array(prev.length).fill(false).fill(true, sectionClicked)
      return isMobile ? prev : newState
    })
  }

  // Dynamically add ref to section if section ref does not already exist
  const addToRefs = (observedSection, index) => {
    refElements.current[index] = observedSection
  }

  const router = useRouter() || {}
  const /** @type string */ pageTestIdScope = router.pathname.split('/').pop()

  /**
   * @param {string} testId
   * @param {string} scope
   * @returns {string}
   */
  const makeTestId = (testId, scope) => {
    if (scope) return `${scope}-${testId}`
    return testId
  }

  const subNavMenuItemCreator = (
    sectionContents,
    intersectingElements,
    updateIntersectingElementsOnClick
  ) => {
    // Find the first section(closest to top of page) that is intersecting the screen area
    const minIntersectingElementIndex = intersectingElements.findIndex((el) => el)
    return (sectionContents || []).map((content, index) => {
      const sectionContentId = content?.fields?.name?.replaceAll(' ', '-')
      const sectionContentName = content?.fields?.name

      return (
        <MenuItem
          key={content?.fields?.sys?.id}
          className={styles['item']}
          tabIndex={-1}
          onClick={updateIntersectingElementsOnClick}
          data-testid={makeTestId(`navbar-item-${index}`, pageTestIdScope)}
        >
          <AscendLink
            section-index={index}
            variant={isMobile ? 'bodyLg' : 'headingMd'}
            href={`#${sectionContentId}`}
            //if mobile view, always highlight first navbar option, else top intersecting section
            data-active={isMobile ? index === 0 : index === minIntersectingElementIndex}
            aria-current={index === minIntersectingElementIndex}
            data-testid={makeTestId(
              `vertical-nav-${sectionContentName}-${content?.sys?.id}`,
              pageTestIdScope
            )}
          >
            {sectionContentName}
          </AscendLink>
        </MenuItem>
      )
    })
  }

  const renderDefaultContent = (sectionContent, index) => {
    const ComponentSection = scrollRender ? StickySubNavSectionScroll : StickySubNavSection
    const handleSetSectionContents = (data) => {
      const currentData = normalizeContent(sectionContents)
      currentData[index] = data
      setSectionContents(currentData)
    }
    return (
      <ComponentSection
        sectionContent={sectionContent}
        index={index}
        addToRefs={addToRefs}
        makeTestId={makeTestId}
        pageTestIdScope={pageTestIdScope}
        isMobile={isMobile}
        scrollRender={scrollRender}
        setSectionContents={handleSetSectionContents}
      />
    )
  }

  return (
    <Section
      className={styles['body-section']}
      data-testid={makeTestId('body-section', pageTestIdScope)}
    >
      <AscendGrid container data-testid={makeTestId('body-outer-grid-container', pageTestIdScope)}>
        <AscendGrid
          item
          xs={12}
          lg={4}
          data-testid={makeTestId('body-outer-grid-container', pageTestIdScope)}
        >
          <MenuList
            className={styles['vertical-menu']}
            ref={refContainer}
            tabIndex={0}
            data-testid={makeTestId('vertical-nav-menu-container', pageTestIdScope)}
          >
            {subNavMenuItemCreator(
              sectionContents,
              intersectingElements,
              updateIntersectingElementsOnClick
            )}
          </MenuList>
        </AscendGrid>
        <AscendGrid item xs={12} lg={8}>
          {(sectionContents || []).map(renderDefaultContent)}
        </AscendGrid>
      </AscendGrid>
    </Section>
  )
}
export { StickySubNavWrapper }
export default StickySubNavWrapper
